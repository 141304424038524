import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";

import "./CouponComplete.css";

interface propsType {}

const CouponComplete = ({}: propsType, ref: any) => {
  const [openCouponComplete, setOpenCouponComplete] = useState(false);
  const [isDownloadSuccess, setIsDownloadSuccess] = useState(false);

  const handleCouponCompleteModalClose = () => setOpenCouponComplete(false);
  const handleCouponCompleteModalOpen = () => setOpenCouponComplete(true);
  const open = (isSuccess: boolean) => {
    setIsDownloadSuccess(isSuccess);
    handleCouponCompleteModalOpen();
  };

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setOpenCouponComplete(false);
      setIsDownloadSuccess(false);
    };
  }, []);

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openCouponComplete}
        onClose={handleCouponCompleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="coupon-complete-modal">
          <Box className="coupon-complete-modal-content">
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={0.5}>
              <Typography className="coupon-complete-text" fontFamily="Noto Sans KR" fontWeight="400" fontSize="11px" fontStyle="normal">
                {isDownloadSuccess ? `쿠폰 다운로드가 완료되었습니다. 쿠폰은 리바트몰 마이페이지 > 내 쿠폰함에서 확인해주세요.` : "다운로드 가능한 수량을 초과했습니다."}
              </Typography>
              <div className="coupon-complete-content-blank" />
              <Button
                sx={{
                  color: "white",
                  backgroundColor: "black",
                  width: "20px",
                  border: 1,
                  borderRadius: 1,
                  "&:focus": {
                    backgroundColor: "black"
                  },
                  "&:hover": {
                    backgroundColor: "black", 
                  },
                  "&:active": {
                    backgroundColor: "black",
                  },
                }}
                onClick={handleCouponCompleteModalClose}>
                <Typography className="coupon-complete-text" fontFamily="Noto Sans KR" fontWeight="700" fontSize="11px" fontStyle="normal">
                  확인
                </Typography>
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default forwardRef(CouponComplete);
