import { useEffect, useState, forwardRef } from "react";

// MUI
import Box from "@mui/material/Box";
import { Button, CardMedia, Divider, Stack, Tab, Tabs } from "@mui/material";

// Local CSS
import dayjs from "dayjs";
import { CommonUtils } from "../../../Utils/common_utils";
import { HttpClientApi, SetProdClickParams } from "../../../interface/client-rest-api";
import styles from "./PrdCpnModules.module.css"

type FunctionType = () => void
type PostMsgType = (payload: {}, target: string) => void

type BroadType = {
  broad_seq: string // video key
  cnslImgPath: string // 상담신청 이미지 URL
  cnslPageUrl: string // 상담신청 페이지 URL
  bnrImgPath: string // 배너 이미지 URL
  bnrPageUrl: string // 배너 페이지 URL
}

type ProdPropType = {
  goodsNm: string
  goodsStCd: string
  salePrc: number
  salePer: number
  goodsImgPath: string
  goodsSn: string
  goodsPageUrl: string
  consumPrc: number
}

type ProdType = {
  name: string
  soldout: string
  salePrice: number
  salePer: number
  thumbImageUrl: string
  productId: string
  goodsPageUrl: string
  consumPrc: number
}

type CouponType = {
  cpnSn: string // 쿠폰번호
  cpnNm: string // 쿠폰명
  cpnTypeCd: string // 쿠폰 유형 코드 (String) 10 : 상품할인 / 20 : 장바구니 할인 / 30: 배송비 할인
  cpnStCd: string // 쿠폰 상태 코드 (String) 20 :사용 / 30 :중지 / 40:만료
  cpnAplyMethCd: string // 쿠폰 적용 방법 코드  10 : 정액(원) / 20 : 정률(%)
  cpnAplyVal: number // 쿠폰 적용 값
  cpnTgtCd: string // 쿠폰 적용 대상 (String) 10 : 상품 / 20: 카테고리 / 30:브랜드 / 40:함께 사면 할인 / 00 : 전체
  minPurAmt: number // 최소 구매 금액
  maxDscntAmt: number // 최대 할인 금액
  mbrMaxIssuQty: number // 회원 최대 발급 수량
  mbrCpnDnldCnt?: number // id당 다운받은 건 수 
  usePsblTermStrtDtime: string // 쿠폰 사용 시작 일시
  usePsblTermFnhDtime: string // 쿠폰 사용 종료 일시
  cpnUsePsblTermTypeCd: string // 쿠폰 사용 기간 타입 ("10" 이면 cpnIssuDtStdDds 사용, 그 외의 경우 usePsblTermFnhDtime 사용)
  cpnIssuDtStdDds: number // 쿠폰 발급 일자 기준 일수 (number)
  dnldPsblTermStrtDtime: string // 쿠폰 다운로드 시작 일시
  dnldPsblTermFnhDtime: string // 쿠폰 다운로드 종료 일시
}

interface propsType {
  userId: string
  custNo: string
  broad_info: BroadType
  prodList: ProdPropType[]
  liveProducts: string[]
  couponList: CouponType[]
  videoMode: number
  goLoginPage: FunctionType
  postMsg: PostMsgType
}

const clientApi = new HttpClientApi();
const cUtils = new CommonUtils();

const PrdCpnModulesPC = ({ userId, custNo, broad_info, prodList, liveProducts, couponList, videoMode, goLoginPage, postMsg }: propsType, ref: any) => {
  const [tabName, setTabName] = useState("prod");

  const [newProdList, setNewProdList] = useState<ProdType[]>([]);

  useEffect(() => {
    let tempNow: any = [];
    let tempNotNow: any = [];
    if (prodList.length > 0) {
      if (prodList[0].goodsNm !== undefined) {
        for (const prod of prodList) {
          if (liveProducts.indexOf(prod.goodsSn) > -1) {
            tempNow.push({
              name: prod.goodsNm,
              soldout: prod.goodsStCd === "03", // 상품 품절 시 true 반환
              salePrice: prod.salePrc,
              salePer: prod.salePer,
              thumbImageUrl: prod.goodsImgPath,
              productId: prod.goodsSn,
              goodsPageUrl: prod.goodsPageUrl,
              consumPrc: prod.consumPrc,
            });
          } else {
            tempNotNow.push({
              name: prod.goodsNm,
              soldout: prod.goodsStCd === "03", // 상품 품절 시 true 반환
              salePrice: prod.salePrc,
              salePer: prod.salePer,
              thumbImageUrl: prod.goodsImgPath,
              productId: prod.goodsSn,
              goodsPageUrl: prod.goodsPageUrl,
              consumPrc: prod.consumPrc,
            });
          }
        }

        setNewProdList([...tempNow, ...tempNotNow]);
      }
    }
  }, [prodList, liveProducts]);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setTabName("prod");
      setNewProdList([]);
    };
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabName(newValue);
  };

  const onClickCoupon = (coupon: CouponType) => {
    if(custNo === undefined || custNo === "") {
      goLoginPage();
    } else {
      // 쿠폰 다운로드
      downloadCoupon(coupon);
    }
  }

  const onClickAllCoupon = () => {
    if(custNo === undefined || custNo === "") {
      goLoginPage();
    } else {
      // 쿠폰 전체 다운로드
      downloadAllCoupon();
    }
  }

  const downloadCoupon = async (coupon: CouponType) => {
    // 쿠폰 다운로드 요청 정보를 저장
    const param: any = {
      broad_seq: broad_info.broad_seq,
      cust_no: custNo,
      coupon_type: coupon.cpnTypeCd,
    };
    await clientApi.put_broad_my_coupon_list(param);

    postMsg({ msg: "downloadCoupon", couponId: coupon.cpnSn}, "*")
  }

  const downloadAllCoupon = async () => {
    let couponIds = ""
    for(const coupon of couponList) {
      // 쿠폰 다운로드 요청 정보를 저장
      const param: any = {
        broad_seq: broad_info.broad_seq,
        cust_no: custNo,
        coupon_type: coupon.cpnTypeCd,
      };
      if(coupon.mbrCpnDnldCnt && coupon.mbrCpnDnldCnt >= coupon.mbrMaxIssuQty) {
        continue;
      }
      couponIds += coupon.cpnSn + ","
      await clientApi.put_broad_my_coupon_list(param);
    }
    couponIds = couponIds.slice(0, couponIds.length - 1);
    postMsg({ msg: "downloadAllCoupon", couponIds: couponIds}, "*")
  }

  const clickProd = async (prod: ProdType) => {
    window.open(prod.goodsPageUrl, "_blank");
    try {
      let param: SetProdClickParams = {
        broad_seq: broad_info.broad_seq,
        user_id: userId,
        prod_id: prod.productId,
        prod_name: prod.name,
        prod_price: `${prod.salePrice}`,
      };

      await clientApi.set_prod_click(param).then((res) => {
        // console.log("상품 클릭 이벤트 : ", res);
      });
    } catch (e) {
      console.error("set_prod_click ERROR : ", e);
    }
  };

  return (
    <Box sx={{
        // width: "calc(100% - 18px)",
        height: "100%",
        bgcolor: "while",
        borderRadius: "16px",
        position: "relative",
        overflow: "hidden",
      }}>
      <Box sx={{ height: "calc(100% - 14px)", padding: "14px"}}>
        {/* 상품 & 쿠폰 상세보기 상단 */}
        {/* <Box className={styles.sub_module_drawer_top}>
          <Box component="img" src="/images/modal-notice.png" className={styles.sub_module_drawer_top_img} />
          <div className={styles.sub_module_drawer_top_text}>&nbsp;라이브 상품 & 쿠폰</div>
        </Box> */}
        {/* 탭 영역 */}
        <Tabs
          value={tabName}
          onChange={handleChange}
          TabIndicatorProps={{
            style: { backgroundColor: "#000000" },
          }}
          sx={{
            ".Mui-selected": {
              color: `#000000 !important`,
            },
          }}
        >
          <Tab value="prod" label="상품목록" sx={{ fontFamily: "Noto Sans KR" }} />
          {couponList.length > 0 && <Tab value="coupon" label="쿠폰" sx={{ fontFamily: "Noto Sans KR" }} />}
        </Tabs>
        {/* 상품 리스트 */}
        {tabName === "prod" && (
          <Box className={styles.live_more_body_box_pc}>
            <Box sx={{ width: "100%", mt: 1 }} className={videoMode === 1 ? `${styles.prod_scroll_pc}` : ""}>
              <nav aria-label="Set Resolution">
                <div className={styles.prod_list}>
                  {/* 상담신청 배너 */}
                  {broad_info.cnslImgPath !== "" && broad_info.cnslImgPath !== null && broad_info.cnslImgPath !== undefined && (
                    <Box
                      className={styles.prod_item_box}
                      onClick={() => {
                        if (broad_info.cnslPageUrl !== "") {
                          window.open(broad_info.cnslPageUrl, "_blank");
                        }
                      }}
                      sx={{ overflow: "hidden" }}
                    >
                      <Box className={styles.prod_item_info}>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                          <CardMedia
                            component="img"
                            image={broad_info.cnslImgPath}
                            sx={{ height: "70px", objectFit: "cover", borderRadius: "7px" }}
                          />
                        </Box>
                      </Box>
                      <Divider />
                    </Box>
                  )}
                  {/* 이미지 배너 */}
                  {broad_info.bnrImgPath !== "" && broad_info.bnrImgPath !== null && broad_info.bnrImgPath !== undefined && (
                    <Box
                      className={styles.prod_item_box}
                      onClick={() => {
                        if (broad_info.bnrPageUrl !== "") {
                          window.open(broad_info.bnrPageUrl, "_blank");
                        }
                      }}
                      sx={{ overflow: "hidden" }}
                    >
                      <Box className={styles.prod_item_info}>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                          <CardMedia
                            component="img"
                            image={broad_info.bnrImgPath}
                            sx={{ height: "70px", objectFit: "cover", borderRadius: "7px" }}
                          />
                        </Box>
                      </Box>
                      <Divider />
                    </Box>
                  )}
                  {/* 상품목록 */}
                  {newProdList.map((obj: ProdType, index: number) => {
                    return (
                      <div key={`prod${index}`}>
                        <Box
                          className={styles.prod_item_box}
                          onClick={() => clickProd(obj)} // 품절이어도 상품 클릭은 됨
                        >
                          <Box className={styles.prod_item_info} sx={{ filter: obj.soldout ? "opacity(0.5)" : "none" }}>
                            <Box className={styles.prod_image_box}>
                              <img src={obj.thumbImageUrl} alt="" />
                              {liveProducts.indexOf(obj.productId) > -1 ? (
                                <>
                                  <div className={styles.live_product_now}>NOW</div>
                                </>
                              ) : (
                                <></>
                              )}
                            </Box>
                            <Box className={styles.prod_desc_box}>
                              <Box className={styles.prod_desc_title_box}>
                                <div className={styles.prod_desc_title_2line}>{obj.name}</div>
                              </Box>
                              <div>
                                {obj.soldout ? (
                                  "품절"
                                ) : obj.consumPrc === obj.salePrice ? (
                                  <span className={styles.sale_price}>{cUtils.numericComma(obj.salePrice) + "원"}</span>
                                ) : (
                                  <Stack direction={"column"} spacing={-0.7} mt={0.3}>
                                    {obj.consumPrc !== undefined && obj.consumPrc !== null && (
                                      <span className={`${styles.original_price} ${styles.line_through} ${styles.color_lightgray}`}>{`${cUtils.numericComma(
                                        obj.consumPrc
                                      )}원`}</span>
                                    )}
                                    <Stack direction="row" display="flex" alignItems="center" spacing={0.5}>
                                      {obj.salePer !== undefined && obj.salePer !== null && obj.salePer > 0 && (
                                        <span className={`${styles.color_red} ${styles.sale_price}`}>{obj.salePer + "%"}</span>
                                      )}
                                      <span className={styles.sale_price}>{`${cUtils.numericComma(obj.salePrice)}원`}</span>
                                    </Stack>
                                  </Stack>
                                )}
                              </div>
                            </Box>
                          </Box>
                          <Divider light />
                        </Box>
                      </div>
                    );
                  })}
                </div>
              </nav>
            </Box>
          </Box>
        )}
        {/* 쿠폰 리스트 */}
        {tabName === "coupon" && (
          <Box className={styles.live_more_body_box_pc}>
            <Box sx={{ width: "100%", mt: 1}} className={videoMode === 1 ? `${styles.cpn_scroll_pc}` : ""}>
              <nav aria-label="Set Resoluion">
                <div className={styles.prod_list}>
                  {couponList.map((coupon: CouponType, index: number) => (
                    <div key={`cpn${index}`}>
                      <Box
                        className={styles.cpn_item_box}
                        onClick={() => onClickCoupon(coupon)}
                        sx={{ filter : coupon.mbrCpnDnldCnt && coupon.mbrCpnDnldCnt >= coupon.mbrMaxIssuQty ? "opacity(0.5)" : ""}}>
                        <Stack direction="row" className={styles.cpn_item_stack}>
                          <Box className={styles.cpn_image_box} sx={{ position: "relative"}}>
                            <img src={ coupon.mbrCpnDnldCnt && coupon.mbrCpnDnldCnt >= coupon.mbrMaxIssuQty ? "/images/icon-cpn-out-list.png" : "/images/icon-cpn-list.png"} alt="" />
                            <Stack direction="column" sx={{ 
                                height: "100%",
                                position: "absolute",
                                top: 1,
                                left: 10,
                                color: "white",
                                display: "flex",
                                justifyContent: "center"
                              }}>
                              <span className={styles.cpn_normal_text} style={{ fontSize: "12px"}}>{coupon.cpnTgtCd === "10" ? "상품할인" : coupon.cpnTgtCd === "20" ? "장바구니 할인" : "배송비 할인"}</span>
                              <span className={styles.cpn_bold_text} style={{ fontSize: "18px", lineHeight: "18px"}}>
                                {cUtils.numericComma(coupon.cpnAplyVal)}
                                <span style={{ fontSize: "12px"}}>
                                  {coupon.cpnAplyMethCd === "10" ? "원" : "%"}
                                </span>
                              </span>
                              <span className={styles.cpn_small_text} style={{ marginTop: "5px"}}>{coupon.mbrCpnDnldCnt ? (coupon.mbrMaxIssuQty - coupon.mbrCpnDnldCnt > 0 ? `${coupon.mbrMaxIssuQty - coupon.mbrCpnDnldCnt}` : "0") : `${coupon.mbrMaxIssuQty}`}개 남음</span>
                            </Stack>
                          </Box> 
                          <Box className={styles.cpn_desc_box}>
                            <Stack direction="column" sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }}>
                              <span className={styles.cpn_bold_text} >{coupon.cpnNm}</span>
                              <span className={styles.cpn_normal_text} style={{color: "gray"}}>{coupon.cpnAplyMethCd === "10" ? `최소 구매 금액 ${cUtils.numericComma(coupon.minPurAmt)}원` : `최대 할인 금액 ${cUtils.numericComma(coupon.maxDscntAmt)}원`}</span>
                              <span className={styles.cpn_normal_text} style={{color: "gray"}}>{coupon.cpnUsePsblTermTypeCd === "10" ? `다운로드 후 ${coupon.cpnIssuDtStdDds}일 까지 사용 가능` : `${dayjs(coupon.usePsblTermFnhDtime).format("YYYY-MM-DD HH:mm")} 까지 사용 가능`}</span>
                            </Stack>
                          </Box>
                          <Box>
                            <img src={"/images/icon-cpn-download.png"} alt="" />
                          </Box>
                        </Stack>
                        <Divider light />
                      </Box>
                    </div>
                  ))}
                </div>
              </nav>
            </Box>
            <Button sx={{ width: "100%", height: "36px", backgroundColor: "black", color: "white", borderRadius: 4, mt: 1,
              "&:focus": {
                backgroundColor: "black"
              },
              "&:hover": {
                backgroundColor: "black", 
              },
              "&:active": {
                backgroundColor: "black",
              },
              }}
              disableRipple 
              onClick={onClickAllCoupon}>
              쿠폰 전체 다운받기
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default forwardRef(PrdCpnModulesPC);
